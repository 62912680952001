<template>
    <div>
        <ValidationObserver ref="observer">
            <app-row-left-label :slots="[6, 3, 3]" :isMarginBetween="true">
                <template v-slot:col-1>
                    <app-input id="vi-post-code" v-model="cAddress.post_code" :placeholder="trans('cdw-postal-code',236)" :label="trans('cdw-postal-code',236)" validatorName="Postcode" validatorRules="required" mask="lettersE3-8" v-on:blur="checkAddress()" :uppercase="true" />
                </template>
                <template v-slot:col-2>
                    <app-input id="vi-house-number" v-model="cAddress.house_number" :placeholder="trans('cdw-house-number',236)" :key="refresh" :label="trans('cdw-house-number',236)" validatorName="Huisnummer" validatorRules="required" v-on:blur="checkAddress()" type="integer_05" />
                </template>
                <template v-slot:col-3>
                    <app-input id="vi-number-additional" v-model="cAddress.house_number_additional" :placeholder="trans('cdw-addition',236)" :label="trans('cdw-addition',236)" v-on:blur="checkAddress()" mask="lettersE3-5" />
                </template>
            </app-row-left-label>

            <app-row-left-label :slots="[6, 6]" :isMarginBetween="true">
                <template v-slot:col-1>
                    <app-input :label="trans('street',257)" v-model="cAddress.street" :placeholder="trans('street',257)" validatorName="Straat" :validatorRules="cAddressRequired" mask="lettersE10-30" />
                </template>
                <template v-slot:col-2>
                    <app-input :label="trans('city',257)" v-model="cAddress.city" :placeholder="trans('city',257)" validatorName="Woonplaats" :validatorRules="cAddressRequired" mask="lettersE10-25" />
                </template>
            </app-row-left-label>

            <app-row-left-label :slots="[6, 6]" :isMarginBetween="true">
                <template v-slot:col-1>
                    <app-select :label="trans('country',257)" v-model="cAddress.country" type="getCountries" :disable="false" validatorName="Land" :validatorRules="cAddressRequired"/>
                </template>
            </app-row-left-label>

        </ValidationObserver>
    </div>
</template>

<script>

import {ValidationObserver} from "vee-validate";
export default {
    components: {
         ValidationObserver
    },

    props: {
        address: {},
        required: { type: Boolean, default: false },
    },

    created() {
        if(this.address.post_code.length != 0 || this.address.house_number.length != 0) {
            if (this.address.house_number_additional != null) {                
                this.checked_address_key = this.address.post_code + this.address.house_number + this.address.house_number_additional;
            } else {
                this.checked_address_key = this.address.post_code + this.address.house_number;
            }
        }         
    },

    computed: {
        cAddress:{
            get() { return this.address },
            set(value) { this.$emit('update:address', value) }
        },

        cErrorMessage:{
            get() { 
                    if (this.address.post_code.length == 0 && this.address.house_number.length == 0) return this.trans('address-required',256)
                    else if (this.address.post_code.length == 0) return this.trans('postal-code-required',256)
                    else if (this.address.house_number.length == 0) return this.trans('house-number-required',256)
                    return ""
                }
        },

        cAddressRequired: {
            get() { 
                
                if(this.required) return "required";
                else if(this.address.post_code.length > 0 || this.address.house_number.length > 0 || (this.address.house_number_additional !== null && this.address.house_number_additional.length > 0) || this.address.city.length > 0 || this.address.street.length > 0) return "required";
                return null;
            }
        }
    },

    data() {
        return {
            checked_address_key : "",
            refresh:0           
        };
    },

    methods: {
        checkAddress() {
            this.refresh++;

            if(this.address.post_code.length == 0 || this.address.house_number.length == 0) {
                this.checked_address_key = "";
                this.$refs.observer.validate();
                return;
            }

            if (this.address.house_number_additional != null) {
                
                this.checked_address_key = this.address.post_code + this.address.house_number + this.address.house_number_additional;
            } else {
                this.checked_address_key = this.address.post_code + this.address.house_number;
            }
            
            this.$refs.observer.validate();            
        },
    },

};

</script>
